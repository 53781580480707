import { DcBaseComponent } from '@deleteagency/dc';
import { deviceObserverModified as deviceObserver } from 'general/js/device-observer-modified';
import Tweets from 'components/twitter-feed/js/tweets';
import Flickity from 'flickity';
import api from 'general/js/api';
import constants from 'general/js/constants';
import { render } from 'preact';
import React from 'preact/compat';

const DEVICE_MAX = 'max',
    DEVICE_TABLET = 'tablet',
    DEVICE_MOBILE = 'mobile',
    DEVICE_DESKTOP = 'desktop';

export default class TwitterFeed extends DcBaseComponent {
    #isLoaded = false;
    #hasWrappers = false;
    _carousel = null;
    _multiImageCards = [];
    _imageCarousels = [];

    #CLASSES = {
        item: 'twitter-feed__item',
        imageItem: 'twitter-feed__item--with-img',
        wrappedItem: 'twitter-feed__item--wrapped',
        wrapper: 'twitter-feed__item--wrapper',
        mutliImage: 'tweet__multi-media',
    }

    #SELECTORS = {
        itemWithoutImage: `.${this.#CLASSES.item}:not(.${this.#CLASSES.imageItem})`,
        wrapper: `.${this.#CLASSES.wrapper}`,
        multiImage: `.${this.#CLASSES.mutliImage}`
    }

    static getNamespace() {
        return 'twitter-feed';
    }

    onInit() {
        this.#loadContent();

        deviceObserver.init(
            {
                [DEVICE_MAX]: Infinity,
                [DEVICE_TABLET]: constants.VIEWPORT_WIDTH_TABLET,
                [DEVICE_MOBILE]: constants.VIEWPORT_WIDTH_MOBILE,
                [DEVICE_DESKTOP]: constants.VIEWPORT_WIDTH_DESKTOP,
            },
            { mobileFirst: false }
        );
    }

    #loadContent = () => {
        if (!this.#isLoaded) {
            this.#isLoaded = true;
            this.#requestContent();
        }
    };

    #requestContent = () => {
        api.get(this.options.endpoint)
            .then((result) => {
                render(<Tweets tweets={result.data.tweets} isInstagram={this.options.isInstagram} parentNode={this.element} />, this.element);
            })
            .then(() => {
                this.#countMultiImageCards();
                this.#checkSize();
                deviceObserver.subscribeOnResize(() => this.#checkSize());
            });
    };

    #countMultiImageCards = () => {
        this._multiImageCards = [...this.element.querySelectorAll(this.#SELECTORS.multiImage)];
    }

    #checkSize = () => {
        if (deviceObserver.is('<=', 'mobile')) {
            if (this.#hasWrappers) this.#removeWrappers();

            if (this._carousel) {
                this._carousel.resize();
            } else {
                this._carousel = new Flickity(this.element, {
                    wrapAround: true,
                    prevNextButtons: true,
                    watchCSS: true,
                    arrowShape: constants.CAROUSEL_ARROW_SHAPE,
                    groupCells: true,
                    resize: false,
                });
            }
        } else {
            if (this._carousel) {
                this._carousel.destroy();
                this._carousel = null;
            }

            if (!this.#hasWrappers) this.#addWrappers();
        }
    }

    #addWrappers = () => {
        const noImageEls = this.element.querySelectorAll(this.#SELECTORS.itemWithoutImage);

        noImageEls.forEach(el => {
            const wrapper = document.createElement('div');
            wrapper.classList.add(this.#CLASSES.item);
            wrapper.classList.add(this.#CLASSES.wrapper);

            if (el.nextElementSibling &&
                !el.nextElementSibling.classList.contains(this.#CLASSES.imageItem) &&
                !el.nextElementSibling.classList.contains(this.#CLASSES.wrappedItem)) {

                // next card is also text-only,
                // wrap two together so they stack

                el.parentNode.insertBefore(wrapper, el);

                const first = el;
                const second = el.nextElementSibling;

                wrapper.appendChild(first);
                first.classList.add(this.#CLASSES.wrappedItem);

                wrapper.appendChild(second);
                second.classList.add(this.#CLASSES.wrappedItem);

            } else if (
                el.nextElementSibling &&
                el.nextElementSibling.classList.contains(this.#CLASSES.imageItem) &&
                !el.classList.contains(this.#CLASSES.wrappedItem)) {

                // next card has an image,
                // wrap card individually so it takes up the same space as an image card without stretching

                el.parentNode.insertBefore(wrapper, el);

                wrapper.appendChild(el);
                el.classList.add(this.#CLASSES.wrappedItem);
            }
        });

        this.#hasWrappers = true;
        this.#setUpImageCarousels();
    }

    #removeWrappers() {
        const wrappers = this.element.querySelectorAll(this.#SELECTORS.wrapper);

        if (wrappers.length > 0) {
            wrappers.forEach(wrapper => {
                [...wrapper.children].forEach(child => {
                    wrapper.parentNode.insertBefore(child, wrapper);
                    child.classList.remove(this.#CLASSES.wrappedItem);
                });
                wrapper.remove();
            });
        }

        this.#hasWrappers = false;
        this.#removeImageCarousels();
    }

    #setUpImageCarousels() {
        if (this._multiImageCards.length != this._imageCarousels.length) {
            this._multiImageCards.forEach(card => {
                let carousel = new Flickity(card, {
                    prevNextButtons: false,
                });

                this._imageCarousels.push(carousel);
            });
        }
    }

    #removeImageCarousels() {
        if (this._imageCarousels.length > 0) {
            this._imageCarousels.forEach(carousel => {
                carousel.destroy();
            });
            this._imageCarousels = [];
        }
    }

    onDestroy() {
        this.#removeImageCarousels();
        this._carousel.destroy();
        this._carousel = null;
    }
}
