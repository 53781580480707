import { deviceObserver } from '@deleteagency/device-observer';
import { DEVICE_MOBILE, DEVICE_TABLET } from 'general/js/app';
import { chunk } from 'lodash';
import nanoid from 'nanoid';
import React, { Fragment } from 'preact/compat';
import PropTypes from 'prop-types';

const NUM_ITEMS_IN_CELL = 4;

class Tweets extends React.PureComponent {
    constructor(props) {
        super(props);

        const { tweets, isInstagram } = props;

        tweets.forEach((tweet) => {
            tweet.ref = React.createRef();
            tweet.content = this.#makeContent(tweet.text);
        });
    }

    #isDesktop = () => deviceObserver.isGt(DEVICE_MOBILE);

    #isTablet = () => deviceObserver.isGt(DEVICE_TABLET);

    #isCalculated = false;

    #makeContent = (text) => {
        const array = text.split(' ');
        return array
            .map((item) => {
                if (item[0] === '#') {
                    return `<span>${item}</span>`;
                }
                if (item.indexOf('http') === 0) {
                    return `<a href="${item}">${item}</a>`;
                }
                return item;
            })
            .join(' ');
    };

    render() {
        const { tweets, isInstagram } = this.props;

        const tweetItems = tweets.map((tweet, index) => {
            let factor = null;
            let tweetClass = '';
            let imageArray = [];
            let hasMultipleImages = false;
            
            if (isInstagram) {
                imageArray = tweet.images ? tweet.images.filter(image => image.imageUrl != null) : [];

                if (imageArray.length > 0) {
                    tweetClass = 'twitter-feed__item--with-img';
                    hasMultipleImages = imageArray.length > 1;
                    factor = 1;
                }

            } else if (tweet.imageUrl) {
                factor = tweet.heightImage / tweet.widthImage;
                tweetClass = 'twitter-feed__item--with-img';
            }

            const id = nanoid();
            return (
                <div key={id} className={`twitter-feed__item tweet ${tweetClass} ${isInstagram && 'instagram'}`} ref={tweet.ref}>
                    <div className="tweet__inner">
                        {factor !== null && !hasMultipleImages && (
                            <a
                                className="tweet__media"
                                target="_blank"
                                rel="noopener nofollow"
                                href={tweet.link}
                                style={{
                                    paddingTop: `${100 * factor}%`,
                                }}
                                aria-labelledby={id}
                            >
                                <img
                                    className="lazyload tweet__img"
                                    data-src={tweet.imageUrl || imageArray[0].imageUrl}
                                    alt={tweet.imageAlt || tweet.date}
                                />
                                {
                                    isInstagram && tweet.postType === 'VIDEO' && (
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 30 30" 
                                            focusable="false" 
                                            aria-hidden="true"
                                            className="tweet__video-thumb"
                                        >
                                            <path d="M30 15L6.8 28.3V1.7L30 15z"/>
                                        </svg>
                                    )
                                }
                            </a>
                        )}
                        { isInstagram && hasMultipleImages && (
                            <div 
                                className="tweet__media tweet__media--multi"
                                style={{
                                    paddingTop: `${100 * factor}%`,
                                }}
                            >
                                <div className="tweet__multi-media">
                                    {imageArray.map(image => {

                                        if (image.mediaType === 'IMAGE') {
                                            return <img
                                                    className="tweet__multi-img"
                                                    src={image.imageUrl}
                                                    alt={""}
                                                    height={image.heightImage}
                                                    width={image.widthImage}
                                                />
                                        }

                                        if (image.mediaType === 'VIDEO') {
                                            return <a 
                                                    href={tweet.link}
                                                    className="tweet__multi-img"
                                                    target="_blank"
                                                    rel="noopener nofollow"
                                                    height={image.heightImage}
                                                    width={image.widthImage}
                                                    aria-labelledby={id}
                                                >
                                                    <img 
                                                        className="tweet__multi-img"
                                                        src={image.imageUrl}
                                                        alt={""}
                                                        height={image.heightImage}
                                                        width={image.widthImage}
                                                    />
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        viewBox="0 0 30 30" 
                                                        focusable="false" 
                                                        aria-hidden="true"
                                                        className="tweet__video-thumb"
                                                    >
                                                        <path d="M30 15L6.8 28.3V1.7L30 15z"/>
                                                    </svg>
                                                </a>
                                        }

                                        
                                    })}
                                </div>
                            </div>
                        )

                        }
                        <a
                            className="tweet__date"
                            target="_blank"
                            rel="noopener nofollow"
                            href={tweet.link}
                        >
                            {tweet.date}
                        </a>
                        {!isInstagram && (
                            <p
                                id={id}
                                className="tweet__text"
                                dangerouslySetInnerHTML={{ __html: tweet.content }}
                            />
                        )}
                    </div>
                </div>
            );
        });

        let rendered = tweetItems;

        // if (this.#isTablet()) {
        //     const chunkedTweets = chunk(tweetItems, NUM_ITEMS_IN_CELL);

        //     const groupTweets = chunkedTweets.map((chunk) => (
        //         <div className="twitter-feed__cell">
        //             {chunk.map((cell) => (
        //                 <Fragment>{cell}</Fragment>
        //             ))}
        //         </div>
        //     ));

        //     rendered = groupTweets;
        // }

        return rendered;
    }

    componentDidMount() {
        this.#checkSize();
        deviceObserver.subscribeOnResize(this.#checkSize);
    }

    #checkSize = () => {
        if (this.#isDesktop()) {
            // this.#set33Layout();
        } else {
            this.#setClean();
            this.#resetParent();
        }
    };

    #set33Layout = () => {
        this.#setPosition(3);
    };

    #setPosition = (difIndex) => {
        const { tweets, parentNode } = this.props;
        if (this.#isCalculated) this.#setClean();
        const parentBounds = parentNode.getBoundingClientRect();
        let mostBottom = 0;
        tweets.forEach((tweet, index) => {
            const upperIndex = index - difIndex;
            if (tweets[upperIndex]) {
                const upperBot = tweets[upperIndex].ref.current.getBoundingClientRect().bottom;
                const tweetTop = tweet.ref.current.getBoundingClientRect().top;
                const difference = upperBot - tweetTop;
                if (difference < 0) {
                    tweet.ref.current.style.transform = `translateY(${difference}px)`;
                }
                const bot = tweet.ref.current.getBoundingClientRect().bottom;
                if (bot > mostBottom) mostBottom = bot;
            }
        });
        parentNode.style.marginBottom = `${mostBottom - parentBounds.bottom}px`;
        this.#isCalculated = true;
    };

    #setClean = () => {
        const { tweets } = this.props;
        tweets.forEach((tweet) => {
            tweet.ref.current.style.transform = '';
        });
    };

    #resetParent = () => {
        const { parentNode } = this.props;
        parentNode.style.marginBottom = `0px`;
    };
}

Tweets.propTypes = {
    tweets: PropTypes.array.isRequired,
    parentNode: PropTypes.obj.isRequired,
};

export default Tweets;
